import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Navigation from './navigation'

const Header = ({ siteTitle }) => (
  <header
    style={{
      margin: `0 auto`,
      padding: `var(--space-4) var(--size-gutter)`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
    }}
  >
      <Link to="/" className="text-2xl text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-4">
        {siteTitle}
      </Link>
    <Navigation />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
