import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { useTransition, animated } from "react-spring"

function Navigation() {
  const [showMenu, setShowMenu] = useState(false)

  const masktransitions = useTransition(showMenu, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const menutransitions = useTransition(showMenu, {
    from: { opacity: 0, transform: "translateX(-100%)" },
    enter: { opacity: 1, transform: "translate(0%)" },
    leave: { opacity: 0, transform: "translateX(-100%)" },
  })

  return (
    <nav>
        <div class="md:ml-auto hidden lg:flex flex-wrap items-center text-base justify-between">
          <Link to="/" className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline ">Home</Link>
          <Link to="/contact" className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Quote</Link>
          <Link to="/gallery" className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Gallery</Link>
          <a href="https://www.yardbook.com/60767/pay_now" target="_blank" rel="noopener noreferrer" className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Pay Your Bill</a>
      </div>
      <span className="text-xl lg:hidden">
        <FontAwesomeIcon icon={faBars} onClick={() => setShowMenu(!showMenu)} />
      </span>

      {masktransitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="bg-black bg-opacity-20 fixed top-0 left-0 w-full h-full z-50"
              onClick={() => setShowMenu(false)}
            ></animated.div>
          )
      )}

      {menutransitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="fixed bg-white top-0 left-0 w-4/5 h-full z-50 shadow p-3"
            >
              <span className="font-bold text-center block mb-3">Navigation</span>
              <ul class="md:ml-auto flex flex-col justify-start items-center text-base justify-between">
                <li><Link
                  to="/"
                  className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline "
                >
                  Home
                </Link>
                </li>
                <li>
                <Link
                  to="/contact"
                  className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2"
                >
                  Quote
                </Link>
                </li>
                <li>
                <Link
                  to="/gallery"
                  className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2"
                >
                  Gallery
                </Link>
                </li>
                <li>
                <a
                  href="https://www.yardbook.com/60767/pay_now"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-5 text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2"
                >
                  Pay Your Bill
                </a>
                </li>
              </ul>
            </animated.div>
          )
      )}
    </nav>
  )
}

export default Navigation
