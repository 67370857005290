import React from "react"
import { Link } from 'gatsby'

export default function Footer() {
  return (
      <footer className="mt-10">
        <div className="grid-cols-3 flex justify-around">
            <div>
                <h3 className="md:text-lg lg:text-2xl text-sm text-center">Navigation</h3>
                <ul className="text-center pl-0">
                    <li><Link to="/" className="text-xs md:text-sm lg:text-lg text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Home</Link></li>
                    <li><Link to="/contact" className="text-xs md:text-sm lg:text-lg text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Quote</Link></li>
                    <li><Link to="/gallery" className="text-xs md:text-sm lg:text-lg text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Gallery</Link></li>
                    <li><a href="https://www.yardbook.com/60767/pay_now" target="_blank" rel="noopener noreferrer" className="text-xs md:text-sm lg:text-lg text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Pay Your Bill</a></li>
                </ul>
            </div>
            <div>
                <h3 className="md:text-lg lg:text-2xl text-sm">Contact Hunter</h3>
                <ul className="text-center pl-0">
                    <li><Link to="tel:+1-607-232-3760" className="text-xs md:text-sm lg:text-lg text-xs text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Call Me</Link></li>
                    <li><Link to="mailto:hunter@qualitycutslawncare.co?subject=Business%20Inquiry" className="text-xs md:text-sm lg:text-lg text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2">Email Me</Link></li>
                </ul>
            </div>
            <div>
                <h3 className="md:text-lg lg:text-2xl text-sm">Leave us a Review</h3>
                <ul className="text-center pl-0">
                <li><a className="text-xs md:text-sm lg:text-lg text-xs text-baseline text-slate-700 hover:text-slate-800 no-underline hover:underline underline-offset-2" href="https://www.google.com/search?q=quality+cuts+lawn+care+waverly&rlz=1C1VDKB_enUS985US985&oq=quality&aqs=chrome.0.69i59l2j69i57j0i131i833i512j0i512j69i60l3.2220j0j8&sourceid=chrome&ie=UTF-8#lrd=0x89d00a507525fc3d:0x3653d991f217cef0,1,,," target="_blank" rel="noopener noreferrer">Google Reviews</a></li>
                </ul>
            </div>
        </div>
      </footer>
  )
}
